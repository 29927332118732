﻿/**
 * System configuration for Angular 2 samples
 * Adjust as necessary for your application needs.
 */
(function(global) {
    System.config({
        paths: {
            // paths serve as alias
            'npm:': 'node_modules/'
        },
        // map tells the System loader where to look for things
        map: {
            // our app is within the app folder
            app: 'app',
            'ng2-ckeditor': 'npm:ng2-ckeditor',
            // angular bundles
            '@angular/core': 'npm:@angular/core/bundles/core.umd.js',
            '@angular/common': 'npm:@angular/common/bundles/common.umd.js',
            '@angular/compiler': 'npm:@angular/compiler/bundles/compiler.umd.js',
            '@angular/platform-browser': 'npm:@angular/platform-browser/bundles/platform-browser.umd.js',
            '@angular/platform-browser-dynamic': 'npm:@angular/platform-browser-dynamic/bundles/platform-browser-dynamic.umd.js',
            '@angular/http': 'npm:@angular/http/bundles/http.umd.js',
            '@angular/http/testing': 'npm:@angular/http/bundles/http-testing.umd.js',
            '@angular/router': 'npm:@angular/router/bundles/router.umd.js',
            '@angular/forms': 'npm:@angular/forms/bundles/forms.umd.js',
            '@ngrx/core': 'npm:@ngrx/core/bundles/core.min.umd.js',
            '@ngrx/store': 'npm:@ngrx/store/bundles/store.min.umd.js',
            'rxjs': 'npm:rxjs',
            "ngx-popover": "npm:ngx-popover",
            "angular2-modal": "npm:angular2-modal",
            "ng2-bootstrap-modal": "npm:ng2-bootstrap-modal",
            "ng2-charts": "npm:ng2-charts",
            "ts-md5": "npm:ts-md5",
            'pubnub-angular2': 'npm:pubnub-angular2',
            'time-ago-pipe': 'npm:time-ago-pipe',
            'mydatepicker': 'npm:mydatepicker/bundles/mydatepicker.umd.js'

        },
        // packages tells the System loader how to load when no filename and/or no extension
        packages: {
            app: {
                main: './main.js',
                defaultExtension: 'js'
            },
            rxjs: {
                main: './bundles/Rx.min.js',
                defaultExtension: 'js'
            },
            "ng2-ckeditor": {
                "main": "lib/index.js",
                "defaultExtension": "js",
            },
            "ngx-popover": { "main": "index.js", "defaultExtension": "js" },
            "ng2-bootstrap-modal": { "main": "index.js", "defaultExtension": "js" },
            "ng2-charts": { "main": "index.js", "defaultExtension": "js" },
            "ts-md5": { "main": "./dist/md5.js", "defaultExtension": "js" },
            "pubnub-angular2": { "main": "./dist/pubnub-angular2.min.js", "defaultExtension": "js" },
            'time-ago-pipe': { 'main': 'time-ago-pipe.js' }
        }
    });
})(this);